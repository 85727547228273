// @vendors
import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

// @components
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import Container from '../components/Container'
import PageBody from '../components/PageBody'
import PostLinks from '../components/PostLinks'
import ContactForm from '../components/ContactForm'
import SocialMedia from '../components/SocialMedia'

const Title = styled.div`
  font-size: 1.615rem;
  font-weight: 400;
  line-height: 25.2px;
  margin-bottom: 34px;

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 36px;
    margin-bottom: 22px;
  }
`

const Gallery = styled.div`
  flex-direction: column;
  margin: 40px 0 0;
  display: none;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 25px 0 0;
    display: flex;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 50px 0 0;
  }
`

const MobileGallery = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 25px 0 0;
    display: none;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 50px 0 0;
    display: none;
  }
`

const FolioImg = styled(Img)`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin-bottom: 40px;
  }
`

const Contacto = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    display: flex;
    justify-content: flex-end;
  }
`

const FolioTemplate = ({ data, pageContext }) => {
  const {
    title,
    heroImage,
    heroMobileImage,
    body,
    gallery,
    mobileGallery,
    metaTitle,
    metaDescription,
    metaImage,
  } = data.contentfulFolio

  const previous = pageContext.prev
  const next = pageContext.next
  const { basePath } = pageContext

  let ogImage
  try {
    ogImage = metaImage.ogimg.src
  } catch (error) {
    ogImage = null
  }
  const folioGallery = gallery
  const heroSources = [
    heroMobileImage.fluid,
    {
      ...heroImage.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDescription.metaDescription}
        image={ogImage}
      />
      <Hero image={heroSources} height={'54vh'} />
      <Container padding={'4em 1.5em 2em'}>
        <Title>{title.title} /</Title>
        <PageBody body={body} />
        <Gallery>
          {folioGallery.map((folioImg, idx) => (
            <FolioImg key={idx} fluid={folioImg.fluid} />
          ))}
        </Gallery>
        <MobileGallery>
          {mobileGallery.map((folioImg, idx) => (
            <FolioImg key={idx} fluid={folioImg.fluid} />
          ))}
        </MobileGallery>
      </Container>
      <PostLinks previous={previous} next={next} basePath={basePath} />
      <Container>
        <Contacto>
          <ContactForm />
          <SocialMedia />
        </Contacto>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulFolio(slug: { eq: $slug }) {
      title {
        title
      }
      body {
        json
      }
      slug
      gallery {
        fluid(maxWidth: 2200, quality: 70) {
          ...GatsbyContentfulFluid
        }
      }
      mobileGallery {
        fluid(maxWidth: 500, quality: 70) {
          ...GatsbyContentfulFluid
        }
      }
      heroImage {
        fluid(maxWidth: 1800, quality: 70) {
          ...GatsbyContentfulFluid
        }
      }
      heroMobileImage {
        fluid(maxWidth: 500, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      metaTitle
      metaDescription {
        metaDescription
      }
      metaImage {
        ogimg: resize(width: 900) {
          src
        }
      }
    }
  }
`

export default FolioTemplate
