import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const BgImg = styled(Img)`
  position: absolute !important;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.section`
  position: relative;
  min-height: 380px;
  height: auto;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
`

const Hero = props => (
  <Wrapper height={props.height}>
    <BgImg fluid={props.image} backgroundColor={'#eeeeee'} />
  </Wrapper>
)

export default Hero
